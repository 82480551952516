@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-full bg-gray-100;

    color: theme("colors.brand.green.dark");
  }

  body,
  #root {
    color: theme("colors.brand.green.dark");
    @apply h-full bg-gray-100;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: theme("fontFamily.display");
  }

  a,
  .link {
    color: theme("colors.brand.orange.medium");
  }

  a:hover,
  .link:hover {
    color: theme("colors.brand.orange.bright");
  }

  input[type="number"],
  input[type="number"]::-webkit-outer-spin-button {
    margin: 0;
    appearance: textfield !important;
  }
}

@layer utilities {
  .brand-hero {
    background: radial-gradient(
      104.43% 92.28% at 11.11% -9.73%,
      rgb(67 201 151 / 94%) 0%,
      #216769 100%
    );
    background-color: white;
  }

  .effect-cta-gradient {
    background: linear-gradient(180deg, #05cd99 20.83%, #05a676 100%);
  }

  .effect-cta-gradient-hover:hover {
    background: linear-gradient(180deg, #05cd99 20.83%, #05a676 100%);
    color: white;
  }

  .no-scroll-bar::-webkit-scrollbar {
    display: none;
  }

  .no-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ec-tooltip {
    background-color: #fff;
    box-shadow: 5px 10px 30px rgb(109 141 173 / 25%);
    border-radius: 10px;
    color: #0d493b;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, 0);
    transition: all 0.1s ease;
    padding: 8px 12px;
  }

  .ec-tooltip::before {
    content: "";
    margin: auto;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: calc(100% - 6px);
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border: 1px solid;
    border-color: #fff;
    background-color: #fff;
  }

  .ec-tooltip span {
    display: block;
    text-align: center;
    @apply block text-center font-medium leading-none;
  }

  .ec-tooltip.point-propertyvalue {
    z-index: 3;
  }

  .ec-tooltip.point-mortgage {
    z-index: 2;
  }

  .ec-tooltip.point-equity {
    z-index: 1;
  }

  .no-highlight {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
  }

  .no-highlight:focus {
    outline: none !important;
  }
}
